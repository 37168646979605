import { GridColumn as Column } from '@progress/kendo-react-grid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ActionButton,
  PrimaryButton
} from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RootState, useReduxDispatch } from 'src/store';
import { FileDetailDto, FileDto } from 'src/store/files/file';
import { mapFileDetailToEdit } from 'src/store/files/file.map';
import {
  createFile,
  deleteFile,
  fetchFiles
} from 'src/store/files/files.actions';
import { selectFile, selectFiles } from 'src/store/files/files.selectors';
import { fetchFileTypes } from 'src/store/filetypes/filetypes.actions';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import { isLoadingFiles } from 'src/store/requests/requests.selectors';
import { FileForm } from './FileForm';

type Props = {};

export const FilesPage: React.FC<Props> = () => {
  const [currentFile, setCurrentFile] = useState<FileDto | null>(null);
  const [file, setFile] = useState<FileDetailDto>({} as FileDetailDto);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const file$ = useSelector((state: RootState) => selectFile(state.files));

  const files = useSelector((state: RootState) => selectFiles(state.files));

  const loadingFiles = useSelector(isLoadingFiles);

  useEffect(() => {
    dispatch(fetchFiles());
    dispatch(fetchFileTypes());
  }, [dispatch]);

  useEffect(() => {
    if (currentFile) {
      setShowDeleteModal(true);
    }
  }, [currentFile]);

  useEffect(() => {
    if (file$) setFile(file$);
  }, [file$]);

  const onCancel = () => {
    setCurrentFile(null);
    setFile({} as FileDetailDto);
    setShowDeleteModal(false);
    setShowEditModal(false);
  };

  const onCreate = () => {
    setShowEditModal(true);
  };

  const onDelete = (file: FileDto) => {
    setCurrentFile(file);
  };

  const onConfirmDelete = async () => {
    if (currentFile) {
      await dispatch(deleteFile(currentFile.id, currentFile));
      setCurrentFile(null);
      setShowDeleteModal(false);
    }
  };

  const onSubmit = async (file: FileDetailDto) => {
    if (activeLocation) {
      const dto = mapFileDetailToEdit(file);
      dto.locationId = activeLocation.id;
      await dispatch(createFile(dto, file.files));
      onCancel();
      dispatch(fetchFiles());
    }
  };

  return (
    <Fragment>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('file.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('file.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate} disabled={activeLocation == null}>
            {`${t('create')} ${t('file.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>
      {activeLocation ? (
        <DataGrid
          items={files}
          loading={loadingFiles}
          // onRowClick={({ dataItem }) => onEdit(dataItem)}
        >
          <Column field="filename" title={t('file.name')} />
          <Column field="fileTypeName" title={t('fileType.singular')} />
          <Column field="locationName" title={t('location.singular')} />
          <Column
            width="100px"
            filterable={false}
            cell={props => (
              <td>
                <ActionButton
                  icon="la la-trash"
                  onClick={() => onDelete(props.dataItem)}
                />
              </td>
            )}
          />
        </DataGrid>
      ) : (
        <Header2>{`${t('select')} ${t('location.singular')}`}</Header2>
      )}
      <Modal
        header={`${t('create')} ${t('file.singular')}`}
        confirmText={t('save.verb')}
        onClose={() => onCancel()}
        open={showEditModal}
      >
        <FileForm file={file} onCancel={onCancel} onSubmit={onSubmit} />
      </Modal>

      <Modal
        width={500}
        header={`${t('delete')} ${t('file.singular')}`}
        confirmText={t('delete')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={showDeleteModal}
        showActions
      >
        <BodyType>
          {t('file.deleteConfirm')} <q>{currentFile?.filename}</q>?
        </BodyType>
      </Modal>
    </Fragment>
  );
};
